import React, { useState, useEffect } from 'react';

import jsPDF from 'jspdf';
import './App.css';

function App() {

  const ampSuggestions = ['10A', '13A', '15A', '16A', '20A', '25A', '32A', '40A', '50A', '63A'];
  const kabelSuggestions = ['1mm2', '1,5mm2', '2,5mm2', '4mm2', '6mm2', '10mm2', '16mm2', '25mm2', '35mm2', '50mm2', '70mm2', '95mm2', '120mm2', '150mm2', '185mm2', '240mm2'];
  const karSuggestions = ['B', 'C', 'D', 'K', 'Z', 'KV', 'OV', 'KV'];
  const lengdeSuggestions = ['1m', '2m', '3m', '4m', '5m', '6m', '7m', '8m', '9m', '10m', '11m', '12m', '13m', '14m', '15m', '16m', '17m', '18m', '19m', '20m'];
  const jfbSuggestions = ['30mA', '100mA', '300mA'];
  const roomSuggestions = [
    'Bad',
    'Barnerom',
    'Bod',
    'Gang',
    'Kjøkken',
    'Kjøkken generelt',
    'Kontor',
    'Kontor',
    'Micro',
    'Oppvaskmaskin',
    'Platetopp',
    'Quooker',
    'Soverom',
    'Soverom 2',
    'Soverom 3',
    'Spisestue',
    'Stue',
    'Teknisk rom',
    'Toalett',
    'Treningsrom',
    'Varmtvannsbereder',
    'Vaskerom',
    'Vaskerom',
    'Ventilasjon',
    'Wc',
];

const spenningSuggestions = ['230V', '400V'];

const nettSuggestions = ['IT', 'TN', 'TN-S', 'TN-C','TN-C-S'];

const vernSuggestions = [
  'Automatsikring',
  'Effektbryter',
  'Jordfeilbryter',
  'Kombiautomat',
  'Motorvernrele',
  'Motorvernbryter',
];
  const [inputContainers, setInputContainers] = useState(Array(28).fill({ description: '', Vern: '', In: '', kar: '', Kabel: '', Lengde: '', Jfb: '' }));
  const [elektroinstallator, setElektroinstallator] = useState({
    FirmaNavn: '',
    Kontaktperson: '',
    Adresse: '',
    PostnrSted: '',
    Telefon: '',
    Epost: '',
  });
  const [anleggsinfo, setAnleggsinfo] = useState({
    FirmaNavn: '',
    Adresse: '',
    PostnrSted: '',
    Nett: '',
    Spenning: '',
    MålerNr: '',
  });
  const [logoURL, setLogoURL] = useState('');
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [savedDataList, setSavedDataList] = useState([]);
  useEffect(() => {
    const savedInputContainers = localStorage.getItem('inputContainers');
    const savedElektroinstallator = localStorage.getItem('elektroinstallator');
    const savedAnleggsinfo = localStorage.getItem('anleggsinfo');
    const savedLogoURL = localStorage.getItem('logoURL');

    if (savedInputContainers) {
      setInputContainers(JSON.parse(savedInputContainers));
    }

    if (savedElektroinstallator) {
      setElektroinstallator(JSON.parse(savedElektroinstallator));
    }

    if (savedAnleggsinfo) {
      setAnleggsinfo(JSON.parse(savedAnleggsinfo));
    }

    if (savedLogoURL) {
      setLogoURL(savedLogoURL);
    }
  }, []);

  useEffect(() => {
    const savedData = localStorage.getItem('savedDataList');
    if (savedData) {
      setSavedDataList(JSON.parse(savedData));
    }
  }, []);

  const saveDataToLocal = (data) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString().replace(/[/, :, ]/g, '_');
    const fileName = `Backup_${formattedDate}_${data.anleggsinfo.Adresse || 'NoAddress'}.json`;
    
    const newDataList = [...savedDataList, { fileName, data }];
    localStorage.setItem('savedDataList', JSON.stringify(newDataList));
    setSavedDataList(newDataList);
  };
  

  // Save data to local storage whenever state changes
  useEffect(() => {
    localStorage.setItem('inputContainers', JSON.stringify(inputContainers));
  }, [inputContainers]);

  useEffect(() => {
    localStorage.setItem('elektroinstallator', JSON.stringify(elektroinstallator));
  }, [elektroinstallator]);

  useEffect(() => {
    localStorage.setItem('anleggsinfo', JSON.stringify(anleggsinfo));
  }, [anleggsinfo]);

  useEffect(() => {
    localStorage.setItem('logoURL', logoURL);
  }, [logoURL]);

  useEffect(() => {
    const loadImage = async () => {
      const img = new Image();
      img.src = logoURL;
  
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });
  
      setIsImageLoaded(true); // Fix the typo here
    };
  
    if (logoURL) {
      loadImage();
    } else {
      setIsImageLoaded(true);
    }
  }, [logoURL]);

  // const addRow = () => {
  //   setInputContainers(...inputContainers, { description: '', Vern: '', In: '', kar: '', Kabel: '', Lengde: '', Jfb: '' });
  // };

  // const removeRows = (count) => {
  //   setInputContainers(inputContainers.slice(0, -count));
  // };
  const handleUploadJSON = (event) => {
    const file = event.target.files[0];
  
    if (!file) {
      return;
    }
  
    const reader = new FileReader();
  
    reader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e.target.result);
  
        setInputContainers(jsonData.inputContainers || Array(28).fill({ description: '', Vern: '', In: '', kar: '', Kabel: '', Lengde: '', Jfb: '' }));
        setElektroinstallator(jsonData.elektroinstallator || {
          FirmaNavn: '',
          Kontaktperson: '',
          Adresse: '',
          PostnrSted: '',
          Telefon: '',
          Epost: '',
        });
        setAnleggsinfo(jsonData.anleggsinfo || {
          FirmaNavn: '',
          Adresse: '',
          PostnrSted: '',
          Nett: '',
          Spenning: '',
          MålerNr: '',
        });
        setLogoURL(jsonData.logoURL || '');
  
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };
  
    reader.readAsText(file);
  };

  const downloadJSON = () => {
    const dataToDownload = {
      inputContainers,
      elektroinstallator,
      anleggsinfo,
      logoURL,
    };
  
    const jsonData = JSON.stringify(dataToDownload, null, 2);
  
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
  
    const fileName = `Backup_kursfortegnelse${anleggsinfo.Adresse || 'Backup_kursfortegnelse'}.json`; // Default to 'NoAddress' if address is not present
  
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const clearInputs = () => {
    // Save the current state before clearing
    const dataToSave = {
      inputContainers,
      elektroinstallator,
      anleggsinfo,
      logoURL,
    };

    saveDataToLocal(dataToSave);

    // Clear the inputs
    setInputContainers(Array(28).fill({ description: '', Vern: '', In: '', kar: '', Kabel: '', Lengde: '', Jfb: '' }));
    setAnleggsinfo({
      FirmaNavn: '',
      Adresse: '',
      PostnrSted: '',
      Nett: '',
      Spenning: '',
      MålerNr: '',
    });
  };


  const loadSavedData = (index) => {
    const { fileName, data } = savedDataList[index];
    setInputContainers(data.inputContainers);
    setElektroinstallator(data.elektroinstallator);
    setAnleggsinfo(data.anleggsinfo);
    setLogoURL(data.logoURL);
    console.log(`Loaded data from file: ${fileName}`);
  };
  const generatePDF = () => {
    if (!isImageLoaded) {
      alert('Prøv igjen, eventuelt sjekk Bilde linken.');
      return;
    }
  
    // Add a delay before generating the PDF
    setTimeout(() => {
  
    const doc = new jsPDF();
    const content = [];
  
    doc.setFontSize(22);
    doc.text('Kursfortegnelse', 35, 14, { align: 'center' });
  
    if (logoURL) {
      const img = new Image();
      img.src = logoURL;
      const imgX = 153;
      const imgY = 22;
      const imgWidth = 50;
      const imgHeight = (imgWidth / img.width) * img.height;
  
      // Add the image to the PDF without specifying the format
      doc.addImage(img, 'auto', imgX, imgY, imgWidth, imgHeight);
    }

    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text('Eier/bruker er ansvarlig for at', 177, 42, { align: 'center' });
    doc.text('den elektriske installasjonen', 177, 47, { align: 'center' });
    doc.text('og det elektriske utstyret er', 177, 52, { align: 'center' });
    doc.text('i henhold til gjeldene regelverk', 177, 57, { align: 'center' });
    doc.text('Arbeid på den faste installasjonen', 177, 62, { align: 'center' });
    doc.text('Skal allitds utføres av fagfolk', 177, 67, { align: 'center' });
    doc.setFont('helvetica', 'normal');
    const drawField = (text, x, y) => {
      doc.text(text, x, y);
    };
    doc.setFont('helvetica', 'bold');
    drawField('Elektroinstallatør', 12, 25);
    drawField('Firma/Navn:', 12, 32);
    drawField('Kontaktperson:', 12, 39);
    drawField('Adresse:', 12, 46);
    drawField('Postnr/sted:', 12, 53);
    drawField('Telefon:', 12, 60);
    drawField('Epost:', 12, 67);

    drawField('Anleggsinfo', 81.5, 25);
    drawField('Firma/Navn:', 81.5, 32);
    drawField('Adresse:', 81.5, 39);
    drawField('Postnr/sted:', 81.5, 46);
    drawField('Nett:', 81.5, 53);
    drawField('Spenning:', 81.5, 60);
    drawField('MålerNr:', 81.5, 67);
    doc.setFont('helvetica', 'normal');



    doc.setFont('helvetica', 'bold');
    doc.text('Kurs', 17, 84, { align: 'center' });
    doc.text('nr.', 17, 88, { align: 'center' });

    doc.text('Lastbeskrivelse / utstyr', 46, 84, { align: 'center' });

    doc.text('Vern', 105, 84, { align: 'center' });
    doc.text('(type)', 105, 88, { align: 'center' });

    doc.text('In', 126, 84, { align: 'center' });
    doc.text('(A)', 126, 88, { align: 'center' });

    doc.text('Kar', 140, 84, { align: 'center' });

    doc.text('S', 158, 84, { align: 'center' });
    doc.text('(mm2)', 158, 88, { align: 'center' });

    doc.text('L', 176, 84, { align: 'center' });
    doc.text('(m)', 176, 88, { align: 'center' });

    doc.text('JFB', 191, 84, { align: 'center' });  
    doc.text('(mA)', 191, 88, { align: 'center' });
    doc.setFont('helvetica', 'normal');
    inputContainers.forEach((item, i) => {
      const startX = 10;
      const startY = 86 + i * 7;

      doc.setFontSize(10);
      doc.setLineWidth(0.1);
      doc.line(8, 90, 202.5, 90);
      doc.line(8, 21, 8, 68);
      doc.line(40, 27, 40, 68);
      doc.line(80, 21, 80, 68);


doc.line(8, 33, 77, 33);
doc.line(8, 40, 77, 40);
doc.line(8, 47, 77, 47);
doc.line(8, 54, 77, 54);
doc.line(8, 61, 77, 61);
doc.line(8, 68, 77, 68);
doc.line(8, 21, 77, 21);
doc.line(8, 27, 77, 27);



      doc.line(8, 80, 8, 90);
      doc.line(25, 80, 25, 90);
      doc.line(92, 80, 92, 90);
      doc.line(119, 80, 119, 90);
      doc.line(133, 80, 133, 90);
      doc.line(148, 80, 148, 90);
      doc.line(168, 80, 168, 90);
      doc.line(184, 80, 184, 90); //
      doc.line(202.5, 80, 202.5, 90);


      doc.line(106, 27, 106, 68);
      doc.line(146, 21, 146, 68);
      doc.line(77, 33, 146, 33);
      doc.line(77, 40, 146, 40);
      doc.line(77, 47, 146, 47);
      doc.line(77, 54, 146, 54);
      doc.line(77, 61, 146, 61);
      doc.line(77, 68, 146, 68);
      doc.line(77, 21, 146, 21);
      doc.line(77, 27, 146, 27);
      doc.line(77, 68, 146, 68);
      
      
      doc.line(8, 80, 202.5, 80);
      doc.line(8, startY + 12, 202.5, startY + 12);
      doc.line(8, 90 + i * 7, 8, 90 + i * 7 + 8);
      doc.line(25, 90 + i * 7, 25, 90 + i * 7 + 8);
      doc.line(92, 90 + i * 7, 92, 90 + i * 7 + 8);
      doc.line(119, 90 + i * 7, 119, 90 + i * 7 + 8);
      doc.line(133, 90 + i * 7, 133, 90 + i * 7 + 8);
      doc.line(148, 90 + i * 7, 148, 90 + i * 7 + 8);
      doc.line(168, 90 + i * 7, 168, 90 + i * 7 + 8);
      doc.line(184, 90 + i * 7, 184, 90 + i * 7 + 8);
      doc.line(202.5, 90 + i * 7, 202.5, 90 + i * 7 + 8);
      doc.setFont('helvetica', 'bold');
      if (i < 9) {
        doc.text(` ${i + 1}.`, startX + 4, startY + 10);
      } else {
        doc.text(`${i + 1}.`, startX + 3, startY + 10);
      } doc.setFont('helvetica', 'normal');
      doc.text(` ${item.description}`, startX + 15, startY + 10);
      doc.text(` ${item.Vern}`, startX + 83, startY + 10);
      doc.text(` ${item.In}`, startX + 112, startY + 10);
      doc.text(` ${item.kar}`, startX + 126, startY + 10);
      doc.text(` ${item.Kabel}`, startX + 139, startY + 10);
      doc.text(` ${item.Lengde}`, startX + 162, startY + 10);
      doc.text(` ${item.Jfb}`, startX + 177, startY + 10);
      content.push(`${i + 1}. ${item.description}, ${item.Vern}, ${item.In}, ${item.kar}, ${item.Kabel}, ${item.Lengde}, ${item.Jfb}`);
    });

    // Add Elektroinstallatør and Anleggsinfo fields and values to the PDF
    const elektroinstallatorX = 41;
    const anleggsinfoX = 107;
    const elektroinstallatorFields = Object.keys(elektroinstallator);
    const anleggsinfoFields = Object.keys(anleggsinfo);

    elektroinstallatorFields.forEach((field, i) => {
      doc.text(`${elektroinstallator[field]}`, elektroinstallatorX, 32 + i * 7);
    });

    anleggsinfoFields.forEach((field, i) => {
      doc.text(`${anleggsinfo[field]}`, anleggsinfoX, 32 + i * 7);
    });

    // Generate PDF name based on address
    let pdfName = 'kursfortegnelser';

    // Check if the address is present and appears to be a valid address
    if (anleggsinfo.Adresse) {
      const addressRegex = /^[0-9a-zA-Z\s,.-]+$/; // Adjust the regex based on your requirements
      if (addressRegex.test(anleggsinfo.Adresse)) {
        pdfName = `Kursfortegnelse_${anleggsinfo.Adresse}`;
      }
    }

    doc.save(`${pdfName}.pdf`);
  }, 500); // Adjust the delay as needed
};

  const handleInputChange = (index, field, value) => {
    setInputContainers((prevContainers) => {
      const updatedContainers = [...prevContainers];
      updatedContainers[index] = { ...updatedContainers[index], [field]: value };
      return updatedContainers;
    });
  };

  const handleLogoChange = (e) => {
    const input = e.target.value.trim().toLowerCase();
  
    if (input === "bbelektro") {
      // Set the logoURL to the desired URL for "bbelektro"
      setLogoURL("https://res.cloudinary.com/rubics/image/upload/h_110/f_auto/iywcqd5x0otfnstza1zs.png");
    } else if (input === "nelfo") {
      // Set the logoURL to the desired URL for "nelfo"
      setLogoURL("https://www.nelfo.no/contentassets/40b32b180d4a45a8851d94f107092f1f/nelfo_rgb.png");
    } else {
      // Set the logoURL to the input value for any other input
      setLogoURL(input);
    }
  };

  const handleElektroinstallatorChange = (field, value) => {
    setElektroinstallator({ ...elektroinstallator, [field]: value });
  };

  const handleAnleggsinfoChange = (field, value) => {
    setAnleggsinfo({ ...anleggsinfo, [field]: value });
  };

  const renderInputContainers = () => {
    const inputRows = [];
  
    inputContainers.forEach((item, i) => {
      const containerWithBorder = (
        <div className="ContainerWithBorder" key={`container_${i}`}>
          <div className="Container">
          <div className="text" style={{ marginLeft: i <= 8 ? '9px' : '0' }}>
            {i + 1}
          </div>
          <input
            className="inputdescription"
            placeholder="Beskrivelse"
            value={item.description}
            onChange={(e) => handleInputChange(i, 'description', e.target.value)}
            list={`roomSuggestions_${i}`}
          />
          <datalist id={`roomSuggestions_${i}`}>
            {roomSuggestions.map((suggestion, index) => (
              <option key={index} value={suggestion} />
            ))}
          </datalist>
        </div>

        <div className="Container" key={`row_${i}_other`}>
        <input
          className="inputnumber"
          placeholder="Vern"
          style={{ marginLeft: '23px' }}
          value={item.Vern}
          onChange={(e) => handleInputChange(i, 'Vern', e.target.value)}
          list={`vernSuggestions_${i}`}
        />
               <datalist id={`vernSuggestions_${i}`}>
        {vernSuggestions.map((suggestion, index) => (
          <option key={index} value={suggestion} />
        ))}
      </datalist>
 <input
        className="inputnumber2"
        placeholder="In"
        value={item.In}
        onChange={(e) => handleInputChange(i, 'In', e.target.value)}
        list={`ampsSuggestions_${i}`}
      />
       <datalist id={`ampsSuggestions_${i}`}>
        {ampSuggestions.map((suggestion, index) => (
          <option key={index} value={suggestion} />
        ))}
      </datalist>
      <input
        className="inputnumber3"
        placeholder="kar"
        value={item.kar}
        onChange={(e) => handleInputChange(i, 'kar', e.target.value)}
        list={`karSuggestions_${i}`}
      />
      <datalist id={`karSuggestions_${i}`}>
        {karSuggestions.map((suggestion, index) => (
          <option key={index} value={suggestion} />
        ))}
      </datalist>

        
      <input
        className="inputnumber4"
        placeholder="Kabel"
        value={item.Kabel}
        onChange={(e) => handleInputChange(i, 'Kabel', e.target.value)}
        list={`kabelSuggestions_${i}`}
      />
      <datalist id={`kabelSuggestions_${i}`}>
        {kabelSuggestions.map((suggestion, index) => (
          <option key={index} value={suggestion} />
        ))}
      </datalist>

      <input
        className="inputnumber5"
        placeholder="Lengde"
        value={item.Lengde}
        onChange={(e) => handleInputChange(i, 'Lengde', e.target.value)}
        list={`lengdeSuggestions_${i}`}
      />
      <datalist id={`lengdeSuggestions_${i}`}>
        {lengdeSuggestions.map((suggestion, index) => (
          <option key={index} value={suggestion} />
        ))}
      </datalist>
      <input
        className="inputnumber6"
        placeholder="Jfb"
        value={item.Jfb}
        onChange={(e) => handleInputChange(i, 'Jfb', e.target.value)}
        list={`jfbSuggestions_${i}`}
      />
      <datalist id={`jfbSuggestions_${i}`}>
        {jfbSuggestions.map((suggestion, index) => (
          <option key={index} value={suggestion} />
        ))}
      </datalist>
      </div>
      </div>
    );

    inputRows.push(containerWithBorder);
  });

  return inputRows;
};

  return (
    <div className="App">
      <header className="App-header">
    <div className='QR-container'>
      <h3>Kursfortegnelse generator</h3>

    <p>Her kan du generere en kursfortegnelse som du enkelt kan printe ut å montere i sikringsskapet,
   fyll ut alle verdier så klikk "lag kursfortegnelse".
   Dette er helt gratis å bruke, ønskede funksjoner kan senders til letticastor@gmail.com.
   Andre tjenester kan også forespørres 
   

   <p>
          🚧 Sikker jobb analyse kommer snart <a href="https://nysja.com/" target="_blank" rel="noopener noreferrer">https://nysja.com/</a> 🚧
        </p>
</p>
{/* <img
        src={require('./qr-code.png')}
        alt="QR Code"
        style={{ width: '50%', maxWidth: '200px', marginBottom: '20px' }}
      />
      <p>Vil sette pris på en liten oppmerksomhet om tjenesten faller i smak.🍺🍺</p>
      <p>Vipps Tlf: 48056261</p> */}

 </div> <input
          className="elektroinstallator-input"
          placeholder="Link til Logoen legges her"
          value={logoURL}
          onChange={handleLogoChange}
        />
<div className="info-sections-container">

  <div className="elektroinstallator-container">
    <h4>Elektroinstallatør</h4>
    {Object.keys(elektroinstallator).map((field, i) => (
      <div key={field} className="field-container">
        <label htmlFor={`elektroinstallator_${field}`}>{field}</label>
        <input
          id={`elektroinstallator_${field}`}
          className="elektroinstallator-input"
          placeholder={field}
          value={elektroinstallator[field]}
          onChange={(e) => handleElektroinstallatorChange(field, e.target.value)}
        />
      </div>
    ))}
  </div>

  <div className="anleggsinfo-container">
  <h4>Anleggsinfo</h4>
  {Object.keys(anleggsinfo).map((field, i) => (
    <div key={field} className="field-container">
      <label htmlFor={`anleggsinfo_${field}`}>{field}</label>
      <input
        id={`anleggsinfo_${field}`}
        className="anleggsinfo-input"
        placeholder={field}
        value={anleggsinfo[field]}
        onChange={(e) => handleAnleggsinfoChange(field, e.target.value)}
        list={`${field.toLowerCase()}Suggestions`} // Use field name to generate dynamic list ID
      />
      <datalist id={`${field.toLowerCase()}Suggestions`}>
        {field.toLowerCase() === 'nett' && nettSuggestions.map((suggestion, index) => (
          <option key={index} value={suggestion} />
        ))}
        {field.toLowerCase() === 'spenning' && spenningSuggestions.map((suggestion, index) => (
          <option key={index} value={suggestion} />
        ))}
      </datalist>
    </div>
  ))}
</div></div>

        {renderInputContainers()}

        <div className="buttoncontainer">
          {/* <button className="button-30" onClick={addRow}disabled>
            Legg til rad
          </button>
          <button className="button-30" onClick={() => removeRows(1)}disabled>
            Fjern 1 rad
          </button> */}
        </div>
        <button className="button-30"  style={{ marginBottom: '20px' }} onClick={generatePDF}>
          Last ned Kursfortegnelse
        </button>
     
<button className="button-30" onClick={clearInputs}>
  Tøm ark og anleggsinfo
</button>

<div className="ContainerWithBorder backuplist">
        <p>Lokale backuper</p>
        
        {savedDataList.map((data, index) => (
  <button key={index} onClick={() => loadSavedData(index)}>
    Last inn {data.fileName} {/* Display the fileName instead of index */}
  </button>
))}
      </div>

<div className="ContainerWithBorder">




<button className="button-30" onClick={downloadJSON}>
  Download JSON Backupdata (Kan være lurt om kursfortegnelsen skal oppdateres senere)
</button>
<p>Her kan du laste opp daten fra JSON Backupdata for å laste inn alle tidligere verdier av en kursfortegnelse</p>
<input
  type="file"
  id="uploadJSON"
  accept=".json"
  onChange={handleUploadJSON}
/> 
 </div>

      </header>
    </div>
  );
}
export default App;
